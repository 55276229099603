import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-14.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import kartell1 from "../../assets/images/sp/14/kartell-1.jpg";
import kartell2 from "../../assets/images/sp/14/kartell-2.jpg";
import kartell3 from "../../assets/images/sp/14/kartell-3.jpg";
import kartell4 from "../../assets/images/sp/14/kartell-4.jpg";
import kartell5 from "../../assets/images/sp/14/kartell-5.jpg";
import kartell6 from "../../assets/images/sp/14/kartell-6.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Kartell Lugano | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 14</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.kartell-lugano.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">Dal 2009</h6>
                                <h1>Kartell - Lugano</h1>
                                <p>Kartell oggi è la sintesi perfetta del suo passato e della sua proiezione verso il
                                    futuro, combinando il grande patrimonio della cultura d’impresa con la voglia di
                                    esplorare nuovi percorsi e nuove esperienze dominate dalla grande passione del fare.</p>
                                <p>Il Flagship Store di Lugano è l’unico negozio monomarca in Svizzera che continua a
                                    crescere grazie anche alla nuova location in Via Nassa 7 dove con il suo
                                    allestimento ha colpito il cuore di tantissimi ticinesi.</p>
                                <p>
                                    <b>Indirizzo</b><br/>
                                    Via Nassa 7, 6900 Lugano<br/><br/>
                                    <b>Telefono</b><br/>+41 91 923 92 22<br/><br/>
                                    <b>Email</b><br/><a href="mailto:info@kartell-lugano.ch">info@kartell-lugano.ch</a><br/><br/>
                                    <b>Orari d'apertura</b><br/>
                                    10:00 - 18:30
                                </p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-center">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={kartell1} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kartell2} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kartell3} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kartell4} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kartell5} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kartell6} alt="Kartell"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
